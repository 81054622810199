import gql from 'graphql-tag'

const userQuery = `
User {
  email
  UserStatus {
    id
    status
  }
  Employee {
    firstName
    lastName
    resourceId
    locale
    city
    country
    Roles {
      id
      role
    }
    Certificates {
      picture
    }
    imageUrl
  }
  UserType {
    type
  }
}
`

const customerQuery = `
Customer {
  firstName
  lastName
  salutation
  phoneNumber
  addressLine1
  addressLine2
  Coordinates {
    latitude
    longitude
  }
  postalCode
  city
  locale
  province
  preferredLanguage
  synergyCmpCode
  isConfirmed
  ${userQuery}
  CustomerJourney {
    id
    journey
  }
  gaUserId
  agreement
}
`

const statusQuery = `
Status {
  id,
  status
}
`

const answersQuery = `
answers {
  questionId
  answerId
}
`

const ctdAnswersQuery = `
answers {
  questionId
  categoryId
  dataType
  answer
}
`

const changesQuery = `
changes{
  questionId,
  categoryId,
  comment
 }
`

const employeeQuery = `
Employee {
  firstName
  lastName
  resourceId
  locale
  city
  country
  Customers {
    firstName
    lastName
    salutation
    phoneNumber
    addressLine1
    addressLine2
    Coordinates {
      latitude
      longitude
    }
    postalCode
    city
    locale
    province
    preferredLanguage
    synergyCmpCode
    isConfirmed
    ${userQuery}
    CustomerJourney {
      id
      journey
    }
    gaUserId
    agreement
  }
  Roles {
    id
    role
  }
  Certificates {
    picture
  }
  imageUrl
  StarRatings {
    categoryCode
    category
    star
  }
}
`

const productCategoryQuery = `
ProductCategory {
  id,
  category,
  code
}
`

const modelQuery = `
Model {
  id,
  code,
  name,
  picture,
  isActive,
  ${productCategoryQuery}
}
`

const colorsQuery = `
ColorResponse {
  id,
  code,
  price,
  type,
  pictureUrl
}
`

// add quantity,
const WizardCompositeKeyArticleCodesQuery = `
WizardCompositeKeyArticleCodes {
  id,
  quantity,
  ArticleCode {
    id,
    code,
    descriptions {
      language,
      value
    }
  }
}
`

const wizardCompositeKeyQuery = `
WizardCompositeKey {
  id,
  compositeKey,
  ${modelQuery},
  ${WizardCompositeKeyArticleCodesQuery}
}
`

const ctdCommentQuery = `
  comments {
    date,
    employeeName,
    comment
  }
`

const ctdRequestQuery = `
CTDRequest {
  id,
  ${employeeQuery},
  ${statusQuery},
  ${ctdAnswersQuery},
  ${changesQuery},
  ${ctdCommentQuery}
  createdTime,
  modifiedTime,
  isLiveClosing,
  approvalCommentId,
  installationTimeId,
  complexityId
}
`

const extraServiceResponseQuery = `
ExtraServiceResponse {
  id
  quantity
  code
  price
  ranks{
        type
        rank
      }
  properties{
        type
        property
      }
  picture
  descriptions {
    language
    value
  }
}
`

const assetsQuery = `
assets {
  assetUrl
  assetDescription {
    language
    value
  }
}
`

const modelSpecsQuery = `
modelSpecs{
  name{
    value
    language
  }
  value
  unit
  description{
    value
    language
  }
}
`

const buybackQuery = `
BuyBackModel {
  ArticleCode {
    id
    code
  }
  quantity,
  amount
}
`

const occasionQuery = `
OccasionModel {
  ArticleCode {
    id
    code
  }
  quantity,
  amount
}
`

const quotationOverview = `
  id,
  ${statusQuery},
  quotationPrice,
  discount,
  quantity,
  ${wizardCompositeKeyQuery},
  ${extraServiceResponseQuery},
  ${colorsQuery},
  ${assetsQuery},
  ${modelSpecsQuery},
  ${buybackQuery},
  ${occasionQuery},
  quotationGroupId,
  quotationGroupRank,
  Employee {
    firstName
    lastName
    resourceId
    locale
    city
    country
    Roles {
    id
      role
  }
  },
  isReady,
  ${answersQuery},
  ${ctdRequestQuery},
  ${customerQuery},
  remark,
  PaymentScheme {
  id,
    descriptions {
    language
    value
  },
  isActive
},
subvention,
  DeliveryWeek {
  week,
    startDate,
    endDate
},
deliveryHour,
houseYear,
provisionCode
`

const quotationQuery = `
QuotationOverview {
  ${quotationOverview}
}
`

const CREATE_CUSTOMER = gql`
  mutation createCustomer($firstName: String!, $lastName: String!, $email: String!, $password: String!, $locate: String!, $preferredLanguage: String, $agreement: Boolean!){
  createCustomer(input:{
    firstName:$firstName,
    lastName:$lastName,
    locale:$locate,
    agreement:$agreement,
    preferredLanguage: $preferredLanguage
    User:{
      email:$email,
      password:$password
    }
  }){
    firstName
  }
}
`

const LOGIN_USER = gql`
  mutation loginUser($email: String!, $password: String!, $remember_me: Boolean){
  loginUser(input: {
    email: $email,
    password: $password,
    remember_me: $remember_me
  }){
    email
    UserType{
      type
    }
    ${customerQuery}
    ${employeeQuery}
  }
}
`

const LOGOUT_USER = gql`
  mutation logoutUser{
    logoutUser
  }
`

const EMPLOYEE_QUERY = gql`
  query employeeQuery{
    employee {
      firstName
      lastName
      resourceId
      locale
      city
      country
      ${userQuery}
      Customers {
        firstName
        lastName
        salutation
        phoneNumber
        addressLine1
        addressLine2
        Coordinates {
          latitude
          longitude
        }
        postalCode
        city
        locale
        province
        preferredLanguage
        synergyCmpCode
        isConfirmed
        ${userQuery}
        CustomerJourney {
          id
          journey
        }
        gaUserId
        agreement
      }
      Roles {
        id
        role
      }
      Certificates {
        picture
      }
      imageUrl
    }
  }
`

const CUSTOMER_QUERY = gql`
  query customer{
      customer {
      firstName
      lastName
      salutation
      phoneNumber
      addressLine1
      addressLine2
      Coordinates {
        latitude
        longitude
      }
      postalCode
      city
      locale
      province
      preferredLanguage
      synergyCmpCode
      isConfirmed
      ${userQuery}
      CustomerJourney {
        id
        journey
      }
      gaUserId
      agreement
    }
  }
`

const CREATE_QUOTATION = gql`
mutation createQuotation($productCategory: String!, $quotationAnswers: [QuotationInput!]!, $salesmanPriceFactor: Float){
  createQuotation(
    input: 
    { 
      productCategory: $productCategory
      Quotation: $quotationAnswers 
      salesmanPriceFactor: $salesmanPriceFactor
      }){
        id,
        Status {
          id, 
          status
        },
        quotationPrice,
        discount,
        quantity,
        ${wizardCompositeKeyQuery},
        ${extraServiceResponseQuery},
        ${colorsQuery},
        ${assetsQuery},
        ${modelSpecsQuery},
        quotationGroupId,
        quotationGroupRank,
        Employee {
          firstName
          lastName
          resourceId
          locale
          city
          country
          Roles {
            id
            role
          }
        },
        isReady,
        ${ctdRequestQuery},
        ModelInstallationTimetable {
          week
          startDate
          endDate
        }
      } 
}
`

const ACTIVITY_QUERY = gql`
query ActivityQuery($limit: Int) {
  activity(limit: $limit) {
    id
    actionDate
    ActivityType {
      id
      type
    }
    ${customerQuery}
    ${employeeQuery}
    Statuses {
      id
      status
    }
  }
}
`

const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($firstName: String, $lastName: String, $preferredLanguage: String, $phoneNumber: String, $addressLine1: String, $postalCode: String, $city: String, $agreement: Boolean ){
  updateCustomer(input:{
    firstName:$firstName,
    lastName:$lastName,
    preferredLanguage: $preferredLanguage
    phoneNumber:$phoneNumber,
	  addressLine1:$addressLine1,
	  postalCode: $postalCode,
    city:$city
    agreement:$agreement
  }){
    firstName
  }
}
`

const CREATE_PREFERRED_CATEGORY = gql`
  mutation createPreferredCategory($category: String!, $requestType:String!){
    createPreferredCategory(input: {category:$category, requestType: $requestType} ){
        id
    ProductCategory {
      id
      category
      code
    }
  }
}
`

const PREFERRED_PRODUCT_CATEGORIES_QUERY = gql`
query preferredProductCategories {
  preferredProductCategories {
    id
    ProductCategory {
      id
      category
      code
    }
  }
}
`

const SAVE_CHOSEN_MODEL = gql`
  mutation saveChosenModel($quotationId: Int!){
   saveChosenModel(input: {Model: [{quotationId: $quotationId}]} ){
    ${quotationOverview}
    }
}
`

const SAVE_COLOR = gql`
  mutation saveColor($quotationId: Int!, $colorsIds: [Int!]){
    saveColor(input: {
      quotationId:$quotationId, 
      colorsIds:$colorsIds
      }){
        ${quotationOverview}
    }
}
`

const SAVE_EXTRA_SERVICES = gql`
  mutation saveExtraServices($quotationId: Int!, $extraServiceInput: [ExtraServiceInput]!){
    saveExtraServices(input: {
      quotationId:$quotationId,
      ExtraService: $extraServiceInput
       } ){
        ${quotationOverview}
    }
}
`

const SAVE_EXTRA_SERVICES_NEW = `
  mutation saveExtraServices($quotationId: Int!, $extraServiceInput: [ExtraServiceInput]!){
    saveExtraServices(input: {
      quotationId:$quotationId,
      ExtraService: $extraServiceInput
       } ){
        ${quotationOverview}
    }
}
`

const QUOTATION_QUERY = gql`
query quotations{
    quotations(last: 5){
      ${quotationOverview}
  }
}
`

const CREATE_HOME_VISIT = gql`
  mutation createHomeVisit($category: String!, $phoneNumber:String!, $addressLine1:String, $postalCode:String!, $city:String!, $country:String! ){
    createHomeVisit(input: {
      category:$category, 
      phoneNumber: $phoneNumber,
      addressLine1:$addressLine1, 
      postalCode:$postalCode, 
      city:$city, 
      country:$country
      } )
}
`

const FILE_QUERY = gql`
query file($documentTypeId: Int, $cmpCode: String) {
  file(documentTypeId: $documentTypeId, cmpCode: $cmpCode) {
    name
    lastModifiedTime,
    DocumentType {
      id,
      synergyTypeId,
      type
    }
  }
}
`

const NEW_FILE_QUERY = `
query file($documentTypeId: Int, $cmpCode: String) {
  file(documentTypeId: $documentTypeId, cmpCode: $cmpCode) {
    name
    lastModifiedTime,
    DocumentType {
      id,
      synergyTypeId,
      type
    }
  }
}
`

const SAVE_DISCOUNT = gql`
  mutation saveDiscount($quotationId: Int!, $discount: Float!){
    saveDiscount(input: {
      quotationId:$quotationId,
      discount:$discount
      }) {
        ${quotationOverview}
      }
}
`

const PAYMENT_SCHEME_QUERY = gql`
query paymentSchemes {
  paymentSchemes {
    id
    descriptions {
      language
      value
    }
  }
}
`

const SALESMAN_STATISTICS_QUERY = gql`
  query salesmanStatistics($period: String!) {
    salesmanStatistics(period: $period){
      appointments
      sales
      conversionRate
      salesBruto
      margin
      provision
    }
}
`

const ORDERS_QUERY = gql`
query orders {
  orders {
    id
    price
    globeOrderNumber
    serialNumber
    PaymentLines {
      id
      sequenceNumber
      percentage
      Status {
        id
        status
      }
    }
    ${modelQuery}
    PaymentScheme {
      id
      descriptions {
        language
        value
      }
    }
    ${quotationQuery}
    Process {
      id
      Step {
        id
        step
        picture
        sequence
      }
    }
    ServiceContracts {
      id
      externalNumber
      externalGuid
      ServiceContractStatus {
        id
        status
      }
      ServiceContractType {
        id
        type
      }
      Model {
        id
        code
        name
        picture
        isActive
        ProductCategory {
          id
          category
          code
        }
      }
      startDate
      endDate
    }
    endDateWarranty
    deliveryDate
    isAppointmentConfirmed
  }
}
`

const RESET_PASSWORD = gql`
  mutation resetPassword($password: String!, $token: String!){
    resetPassword(input: {
      password:$password,
      token:$token
      }) {
        email
        UserStatus {
          id
          status
        }
        ${customerQuery}
        ${employeeQuery}
        UserType {
          type
        }
      }
    } 
`

const SEND_FORGOT_PASSWORD_EMAIL = gql`
  mutation sendForgotPasswordEmail($email: String!){
    sendForgotPasswordEmail(input: {
      email:$email
    }) 
}
`

const CUSTOMER_BY_CMP_CODE_QUERY = gql`
  query customerByCmpCode($cmpCode: String!) {
    customerByCmpCode(cmpCode: $cmpCode){
      firstName
      lastName
      salutation
      phoneNumber
      addressLine1
      addressLine2
      postalCode
      city
      locale
      province
      preferredLanguage
      synergyCmpCode
      ${userQuery}
      CustomerJourney {
        id
        journey
      }
    }
}
`

const FILES_BY_ORDER_NUMBER_QUERY = gql`
query filesByOrderNumber($orderNumber: Int!) {
  filesByOrderNumber(orderNumber: $orderNumber) {
    name
    lastModifiedTime
    DocumentType {
      synergyTypeId
      type
    }
  }
}
`

const PAY_ORDER = gql`
mutation payOrder($paymentLineId: Int!) {
  payOrder(input: {
    paymentLineId: $paymentLineId
  })
}
`

const CREATE_CALL_BACK = gql`
mutation createCallBack {
  createCallBack
}
`

const CREATE_HOME_VISIT_TECHNICAL = gql`
mutation createHomeVisitTechnical {
  createHomeVisitTechnical
}
`

const CHANGE_PASSWORD = gql`
mutation changePassword($currentPassword: String!, $newPassword: String!) {
  changePassword(input: {
    currentPassword: $currentPassword,
    newPassword: $newPassword
  })
}
`

const ALERTS_QUERY = gql`
query alerts {
  alerts {
    id
    AlertType {
      id
      type
    }
    messageId
    cmpCode
    dateStart
    dateEnd
    isActive
  }
}
`

const DISMISS_ALERT = gql`
mutation dismissAlert($alertId: Int!) {
  dismissAlert(input: {
    alertId: $alertId,
  })
}
`

const SALESMAN_COUNTRY_MONTHLY_STATISTICS_QUERY = gql`
query salesmanCountryMonthlyStatistics {
  salesmanCountryMonthlyStatistics {
    date
    SalesmanCountryStatistics {
      appointments
      sales
      conversionRate
      salesBruto
      margin
      provision
    }
  }
}
`

const SALESMAN_MONTHLY_STATISTICS_QUERY = gql`
query salesmanMonthlyStatistics {
  salesmanMonthlyStatistics {
    date
    SalesmanStatistics {
      appointments
      sales
      conversionRate
      salesBruto
      margin
      provision
    }
  }
}
`

const UPDATE_QUOTATION_PRICE = gql`
  mutation updateQuotationPrice($quotationId: Int!, $price: Float!){
    updateQuotationPrice(input: {
      quotationId:$quotationId,
      price: $price
    }) {
      ${quotationOverview}
    }
}
`

const UPDATE_QUOTATION_ISREADY = gql`
  mutation updateQuotationIsReady($quotationId: Int!, $isReady: Boolean!){
    updateQuotationIsReady(input: {
      quotationId:$quotationId,
      isReady: $isReady
    })
}
`

const REGISTER_WIZARD_KEY = gql`
  mutation registerWizardKey($quotationId: Int!){
    registerWizardKey(quotationId:$quotationId)
}
`

const GET_QUOTATION_IMAGES = gql`
  query getQuotationImages($quotationId: Int!){
    getQuotationImages(quotationId:$quotationId) {
      name
    }
}
`

const NEW_GET_QUOTATION_IMAGES = `
  query getQuotationImages($quotationId: Int!){
    getQuotationImages(quotationId:$quotationId) {
      name
    }
}
`

const DELETE_ORDER_FILE = gql`
  mutation deleteOrderFile($fileName: String! $quotationId: Int!){
    deleteOrderFile(input: {
      fileName:$fileName
      quotationId:$quotationId
    })
}
`

const CREATE_SANINET_QUOTATION = gql`
mutation createSaninetQuotation($model: String!, $csv: File!, $image: File!, $salesmanPriceFactor: Float) {
  createSaninetQuotation(
    input: {
      model: $model, 
      csv: $csv, 
      image: $image, 
      salesmanPriceFactor: $salesmanPriceFactor
    }) {
    ${quotationQuery}
  }
}
`

const CTD_REQUESTS_QUERY = `
query ctdRequests($id: Int) {
  ctdRequests(id: $id)  {
    id,
    ${quotationQuery},
    ${employeeQuery},
    ${statusQuery},
    ${ctdAnswersQuery},
    ${changesQuery}, 
    ${ctdCommentQuery},
    createdTime,
    modifiedTime,
    isLiveClosing,
    approvalCommentId,
    installationTimeId,
    complexityId
  } 
}
`

const UPLOAD_CTD_FILE_MUTATION = `mutation uploadCTDFile($file: File!,$quotationId: Int!,$existingFileName: String){uploadCTDFile(file: $file,quotationId: $quotationId,existingFileName: $existingFileName) {success,fileName,errorMessage}}`

const CTD_CREATE_REQUEST_MUTATION = `
mutation ctdCreateRequest(
  $quotationId: Int!,
  $answers: [CTDAnswerInput!]!,
  $isLiveClosing: Boolean
) {
  ctdCreateRequest(input: {
    quotationId: $quotationId,
    answers: $answers,
    isLiveClosing: $isLiveClosing
  }) {
    id,
    ${quotationQuery},
    ${employeeQuery},
    ${statusQuery},
    ${ctdAnswersQuery},
    ${changesQuery}, 
    createdTime,
    modifiedTime
  }
  }
`

const CTD_HANDLE_REQUEST_MUTATION = `
mutation ctdHandleRequest(
  $requestId: Int!
) {
  ctdHandleRequest(
    requestId: $requestId
    ) {
    id,
    ${quotationQuery},
    ${employeeQuery},
    ${statusQuery},
    ${ctdAnswersQuery},
    ${changesQuery}, 
    createdTime,
    modifiedTime
  }
  }
`

const CTD_REQUEST_CHANGES_MUTATION = `
mutation ctdRequestChanges(
  $requestId: Int!,
  $ctdChangeInput: [CTDChangeInput!]!,
) {
  ctdRequestChanges(
    input: {
      requestId: $requestId,
      changes: $ctdChangeInput
    }) {
    id,
    ${quotationQuery},
    ${employeeQuery},
    ${statusQuery},
    ${ctdAnswersQuery},
    ${changesQuery}, 
    createdTime,
    modifiedTime
  }
  }
`

const CTD_UPDATE_ANSWERS_MUTATION = `
mutation ctdUpdateAnswers(
  $requestId: Int!,
  $answers: [CTDAnswerInput!]!,
  $isLiveClosing: Boolean
) {
  ctdUpdateAnswers(input: {
      requestId: $requestId,
      answers: $answers,
      isLiveClosing: $isLiveClosing
    }) {
    id,
    ${quotationQuery},
    ${employeeQuery},
    ${statusQuery},
    ${ctdAnswersQuery},
    ${changesQuery}, 
    createdTime,
    modifiedTime
  }
  }
`

const CTD_APPROVE_REQUEST_MUTATION = `
mutation ctdApproveRequest(
  $requestId: Int!,
  $approveCommentId: Int,
  $installationTimeId: Int,
  $complexityId: Int
) {
  ctdApproveRequest(input: {
      requestId: $requestId,
      approveCommentId: $approveCommentId,
      installationTimeId: $installationTimeId,
      complexityId: $complexityId
  }) {
    id,
    ${quotationQuery},
    ${employeeQuery},
    ${statusQuery},
    ${ctdAnswersQuery},
    ${changesQuery}, 
    createdTime,
    modifiedTime
  }
  }
`

const INSTALLATION_TIMETABLE_QUERY = `
query installationTimetable($id: Int!) {
  installationTimetable(id: $id) {
    week
    startDate
    endDate
  }
}
`

const SAVE_BUYBACK_MODEL_MUTATION = `
mutation saveBuyBackModel(
  $quotationId: Int!,
  $code: String,
  $quantity: Int,
  $amount: Float
) {
  saveBuyBackModel(
  input: {
      quotationId: $quotationId
      code: $code
      quantity: $quantity
      amount: $amount
  }
  ) {
    ${quotationOverview}
  }
}
`

const SAVE_OCCASION_MODEL_MUTATION = `
mutation saveOccasionModel(
  $quotationId: Int!
  $code: String
  $amount: Float
) {
  saveOccasionModel(
    input: {
      quotationId: $quotationId
      code: $code
      amount: $amount
    }
  ) {
    ${quotationOverview}
  }
}
`

const SAVE_REMARK_MUTATION = `
mutation saveRemark(
  $quotationId: Int!,
  $remark: String!
) {
  saveRemark(input: {
      quotationId: $quotationId,
      remark: $remark
  }) {
    ${quotationOverview}
  }
  }
`

const CTD_DELETE_REQUEST_MUTATION = `
mutation ctdDeleteRequest(
  $requestId: Int!,
) {
  ctdDeleteRequest(requestId: $requestId)
}
`

const CHANGE_USER_EMAIL_MUTATION = `
mutation changeUserEmail(
  $email: String!
) {
  changeUserEmail(email: $email)
}
`

const CREATE_AUTH_TOKEN = `
mutation createAuthToken {
  createAuthToken {
    token,
    hasAllDocuments,
    isValid,
  }
}
`

const SAVE_QUOTATION_DATA = `
mutation saveQuotationData(
  $quotationId: Int!,
  $deliveryWeek: Int,
  $deliveryHour: String,
  $paymentSchemeId: Int!,
  $houseYear: Int,
  $subvention: Float
) {
  saveQuotationData(input: {
    quotationId: $quotationId,
    deliveryWeek: $deliveryWeek,
    deliveryHour: $deliveryHour,
    paymentSchemeId: $paymentSchemeId,
    houseYear: $houseYear,
    subvention: $subvention
  }) {
    ${quotationOverview}
  }
}
`

const DELETE_QUOTATION_EXTRA_SERVICE = `
mutation deleteQuotationExtraService(
  $quotationId: Int!,
  $extraServiceId: Int!
) {
  deleteQuotationExtraService(input: {
    quotationId: $quotationId,
    extraServiceId: $extraServiceId
  }) {
    ${quotationOverview}
  }
}
`

const MODEL_EXTRA_SERVICES = `
query modelExtraServices($quotationId: Int!) {
  modelExtraServices(quotationId: $quotationId) {
    ${quotationOverview}
  }
}
`

const CTD_ADD_COMMENT_MUTATION = `
mutation ctdAddComment(
  $requestId: Int!,
  $comment: String!
) {
  ctdAddComment(input: {
    requestId: $requestId,
    comment: $comment
  }) {
      ${quotationQuery}
      id,
      ${employeeQuery},
      ${statusQuery},
      ${ctdAnswersQuery},
      ${changesQuery},
      ${ctdCommentQuery}
      createdTime,
      modifiedTime,
      isLiveClosing,
      approvalCommentId,
      installationTimeId,
      complexityId
  }
}
`

export {
  LOGIN_USER,
  LOGOUT_USER,
  CREATE_CUSTOMER,
  EMPLOYEE_QUERY,
  CUSTOMER_QUERY,
  CREATE_QUOTATION,
  ACTIVITY_QUERY,
  UPDATE_CUSTOMER,
  CREATE_PREFERRED_CATEGORY,
  PREFERRED_PRODUCT_CATEGORIES_QUERY,
  SAVE_CHOSEN_MODEL,
  SAVE_COLOR,
  SAVE_EXTRA_SERVICES,
  QUOTATION_QUERY,
  CREATE_HOME_VISIT,
  FILE_QUERY,
  SAVE_DISCOUNT,
  PAYMENT_SCHEME_QUERY,
  SALESMAN_STATISTICS_QUERY,
  ORDERS_QUERY,
  RESET_PASSWORD,
  SEND_FORGOT_PASSWORD_EMAIL,
  CUSTOMER_BY_CMP_CODE_QUERY,
  FILES_BY_ORDER_NUMBER_QUERY,
  PAY_ORDER,
  CREATE_CALL_BACK,
  CREATE_HOME_VISIT_TECHNICAL,
  CHANGE_PASSWORD,
  ALERTS_QUERY,
  DISMISS_ALERT,
  SALESMAN_COUNTRY_MONTHLY_STATISTICS_QUERY,
  SALESMAN_MONTHLY_STATISTICS_QUERY,
  UPDATE_QUOTATION_PRICE,
  UPDATE_QUOTATION_ISREADY,
  REGISTER_WIZARD_KEY,
  GET_QUOTATION_IMAGES,
  DELETE_ORDER_FILE,
  CREATE_SANINET_QUOTATION,
  CTD_REQUESTS_QUERY,
  UPLOAD_CTD_FILE_MUTATION,
  CTD_CREATE_REQUEST_MUTATION,
  CTD_HANDLE_REQUEST_MUTATION,
  CTD_REQUEST_CHANGES_MUTATION,
  CTD_UPDATE_ANSWERS_MUTATION,
  CTD_APPROVE_REQUEST_MUTATION,
  INSTALLATION_TIMETABLE_QUERY,
  SAVE_BUYBACK_MODEL_MUTATION,
  SAVE_OCCASION_MODEL_MUTATION,
  SAVE_REMARK_MUTATION,
  CTD_DELETE_REQUEST_MUTATION,
  CHANGE_USER_EMAIL_MUTATION,
  CREATE_AUTH_TOKEN,
  NEW_FILE_QUERY,
  NEW_GET_QUOTATION_IMAGES,
  SAVE_QUOTATION_DATA,
  DELETE_QUOTATION_EXTRA_SERVICE,
  MODEL_EXTRA_SERVICES,
  SAVE_EXTRA_SERVICES_NEW,
  CTD_ADD_COMMENT_MUTATION
}