import { useQuery } from '@apollo/react-hooks';
import { QUTATION_PDF_QUERY } from '../../queries/cms/queries'
import useI18next from '../useI18next'
import { cmsClient } from '../../graphql-endpoints/graphqlEndpoints'

const useQuotationPdfQuery = () => {
  const language = JSON.parse(sessionStorage.getItem('my-environment')).languages?.language
  let locale = ''
  const { loading, error, data } = useQuery(QUTATION_PDF_QUERY, { client: cmsClient });

  switch (JSON.parse(sessionStorage.getItem('my-environment')).languages?.locale.toLowerCase()) {
    case 'en':
    case 'en_en':
      locale = 'en'
      break
    case 'nl':
    case 'nl_nl':
      locale = 'nl'
      break
    case 'fr':
    case 'fr_fr':
      locale = 'fr'
      break
    case 'de':
    case 'de_de':
      locale = 'de'
      break
    case 'it':
    case 'it_it':
      locale = 'it'
      break
    case 'nl_be':
      locale = 'nl_BE'
      break
    case 'fr_be':
      locale = 'fr_BE'
      break
    default:
      locale = 'nl'
  }

  if (loading) return { loading }
  if (error) return { error }

  const internationalizationJson = data.card.Card[0].internationalization_json
  const I18next = () => useI18next(internationalizationJson)
  const { i18next } = I18next()

  const lables = {
    pdfCoverPage: i18next.t(`pdf_cover_page.${language}`),
    pdfQuotationStepsInformationPage: i18next.t(`pdf_quotation_steps_information_page.${language}`),
    pdfAuthor: i18next.t(`pdf_author.${language}`),
    pdfKeywords: i18next.t(`pdf_keywords.${language}`),
    pdfSubject: i18next.t(`pdf_subject.${language}`),
    pdfTitle: i18next.t(`pdf_title.${language}`),
    productNameLabel: i18next.t(`product_name_label.${language}`),
    customerLabel: i18next.t(`customer_label.${language}`),
    dateLabel: i18next.t(`date_label.${language}`),
    priceLable: i18next.t(`price_lable.${language}`),
    techicalDetailsLabel: i18next.t(`techical_details_label.${language}`),
    pdfQuotationFooter: i18next.t(`pdf_quotation_footer.${language}`),
    pdfOrderFooter: i18next.t(`pdf_order_footer.${locale}`),
    pdfLogo: i18next.t(`pdf_logo.${locale}`),
    quotationLabel: i18next.t(`quotation_label.${language}`),
    orderLabel: i18next.t(`order_label.${language}`),
    pdfOrderGeneralConditions: i18next.t(`pdf_order_general_conditions.${locale}`, { returnObjects: true }),
    customerNumber: i18next.t(`customer_number.${language}`),
    localAdviser: i18next.t(`local_adviser.${language}`),
    subventionValue: i18next.t(`subvention_value.${language}`),
    versionNumber: i18next.t(`version_number.${language}`),
    optionsAndConditionsLabel: i18next.t(`options_and_conditions_label.${language}`),
    orderValueLabel: i18next.t(`order_value_label.${language}`),
    discountLabel: i18next.t(`discount_label.${language}`),
    valueToPayLabel: i18next.t(`value_to_pay_label.${language}`),
  }

  return {
    quotationPdfLabels: lables,
    loading,
    error
  }
}

export default useQuotationPdfQuery